<template>
  <div class="list">
    <heade currentrStr=""></heade>
    <div class="list-box">
      <a-breadcrumb class="mbx">
        <a-breadcrumb-item
          ><span @click="look('./index')">首页</span></a-breadcrumb-item
        >
        <a-breadcrumb-item>赛题</a-breadcrumb-item>
      </a-breadcrumb>
      <a-divider dashed />
      <div v-if="this.role == '学生' || this.role == '未登录'">
        <div style="text-align: left">
          <span>分类：</span>
          <!-- <el-cascader
            size="mini"
            style="margin-right: 20px"
            v-model="tagsValue"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
          ></el-cascader> -->
          <el-select
            v-model="tagsValue"
            placeholder="请选择"
            size="mini"
            style="margin-right: 20px"
            @change="handleChange"
          >
            <el-option label="全部" value="">全部</el-option>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
              <span style="float: left">{{ item.value }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
          <span>分类：</span>
          <!-- <el-cascader
            size="mini"
            style="margin-right: 20px"
            v-model="tagsValue"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
          ></el-cascader> -->
          <el-select
            v-model="tagsValueS"
            placeholder="请选择"
            size="mini"
            style="margin-right: 20px"
            @change="handleChangeS"
          >
            <el-option label="全部" value="">全部</el-option>
            <el-option
              v-for="item in optionsS"
              :key="item"
              :label="item"
              :value="item"
            >
              <span style="float: left">{{ item }}</span>
              <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span> -->
            </el-option>
          </el-select>
          <span>单位：</span
          ><el-input
            v-model="company"
            placeholder="请输入单位"
            style="width: 200px"
            size="mini"
          ></el-input>
          <span style="margin-left: 20px">名称：</span
          ><el-input
            v-model="titleS"
            placeholder="请输入名称"
            style="width: 200px"
            size="mini"
          ></el-input>
          <span style="margin-left: 20px">难度：</span
          ><el-input
            v-model="level"
            placeholder="请输入难度"
            style="width: 100px"
            size="mini"
          ></el-input>

          <el-button
            type="primary"
            style="margin-bottom: 10px; margin-left: 10px"
            size="mini"
            @click="allList('zhi')"
          >
            搜索</el-button
          >
        </div>
        
        <el-table
          :data="tableDatak"
          style="width: 100%"
          stripe
          border
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column prop="code" label="编号"> </el-table-column>
          <el-table-column prop="title" label="项目名称" width="400">
          </el-table-column>
          <el-table-column prop="project" label="大赛名称" width="500">
          </el-table-column>
          <el-table-column prop="level" label="难度"> </el-table-column>
          <el-table-column prop="count" label="选题队伍数"> </el-table-column>

          <!-- <el-table-column prop="require" label="参赛要求"> </el-table-column> -->
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="editFunStu('edit', scope.row)"
                type="primary"
                plain
                size="mini"
                >查看</el-button
              >
              <!-- <el-divider direction="vertical"></el-divider>
              
                <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delFun(scope.row._id)">
                  <el-button slot="reference" type="text" size="small" 
                    >删除</el-button
                  >
                </el-popconfirm> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 15, 20, 25]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: right; margin-top: 10px"
          v-if="total > 0"
        >
        </el-pagination>
      </div>
      <div v-else>
        <a-tabs
          default-active-key="1"
          tab-position="left"
          @change="handleClick"
        >
          <a-tab-pane key="1" tab="我的赛题">
            <div style="text-align: left">
              <el-button
                type="success"
                style="float: right; margin-bottom: 10px"
                size="mini"
                @click="editFun('add')"
                >发表赛题项目</el-button
              >
            </div>

            <el-table :data="tableData" style="width: 100%" stripe border>
              <el-table-column prop="code" label="编号"> </el-table-column>
              <el-table-column prop="title" label="项目名称" width="400">
              </el-table-column>
              <el-table-column prop="project" label="大赛名称" width="500">
              </el-table-column>
              <el-table-column prop="level" label="难度"> </el-table-column>
              <!-- <el-table-column prop="require" label="参赛要求">
              </el-table-column> -->
              <el-table-column prop="check" label="状态">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.check == '审核通过'"
                    style="color: green"
                    >{{ scope.row.check }}</span
                  >
                  <span v-else style="color: red">{{ scope.row.check }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="count" label="选题队伍数">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="240">
                <template slot-scope="scope">
                  <el-button
                    @click="editFun('edit', scope.row)"
                    type="primary"
                    plain
                    size="mini"
                    style="margin-right: 10px"
                    >{{ scope.row.edit ? "编辑" : "查看" }}</el-button
                  >
                  <!-- <el-divider
                    direction="vertical"
                    v-if="scope.row.edit"
                  ></el-divider> -->
                  <!-- <el-button type="text" size="small">删除</el-button> -->
                  <el-popconfirm
                    title="确定删除吗？"
                    @confirm="delFun(scope.row._id)"
                  >
                    <el-button
                      style="margin-right: 10px"
                      slot="reference"
                      type="danger"
                      plain
                      size="mini"
                      v-if="scope.row.edit"
                      >删除</el-button
                    >
                  </el-popconfirm>

                  <el-button
                    @click="chooseFun(scope.row._id)"
                    v-if="!scope.row.edit"
                    type="success"
                    plain
                    size="mini"
                    >选题队伍</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="赛题库">
            <div style="text-align: left">
              <span>分类：</span>
              <!-- <el-cascader
            size="mini"
            style="margin-right: 20px"
            v-model="tagsValue"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
          ></el-cascader> -->
              <el-select
                v-model="tagsValue"
                placeholder="请选择"
                size="mini"
                style="margin-right: 20px"
                @change="handleChange"
              >
                <el-option label="全部" value="">全部</el-option>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.label
                  }}</span>
                </el-option>
              </el-select>
              <span>分类：</span>
          <!-- <el-cascader
            size="mini"
            style="margin-right: 20px"
            v-model="tagsValue"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
          ></el-cascader> -->
          <el-select
            v-model="tagsValueS"
            placeholder="请选择"
            size="mini"
            style="margin-right: 20px"
            @change="handleChangeS"
          >
            <el-option label="全部" value="">全部</el-option>
            <el-option
              v-for="item in optionsS"
              :key="item"
              :label="item"
              :value="item"
            >
              <span style="float: left">{{ item }}</span>
              <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span> -->
            </el-option>
          </el-select>
              <span>单位：</span
              ><el-input
                v-model="company"
                placeholder="请输入单位"
                style="width: 200px"
                size="mini"
              ></el-input>
              <span style="margin-left: 20px">名称：</span
              ><el-input
                v-model="titleS"
                placeholder="请输入名称"
                style="width: 200px"
                size="mini"
              ></el-input>
              <span style="margin-left: 20px">难度：</span
              ><el-input
                v-model="level"
                placeholder="请输入难度"
                style="width: 100px"
                size="mini"
              ></el-input>

              <el-button
                type="primary"
                style="margin-bottom: 10px; margin-left: 10px"
                size="mini"
                @click="allList"
              >
                搜索</el-button
              >
            </div>
            <el-table :data="tableDatak" style="width: 100%" stripe border>
              <el-table-column prop="code" label="编号"> </el-table-column>
              <el-table-column prop="title" label="项目名称" width="400">
              </el-table-column>
              <el-table-column prop="project" label="大赛名称" width="500">
              </el-table-column>
              <el-table-column prop="level" label="难度"> </el-table-column>
              <el-table-column prop="count" label="选题队伍数">
              </el-table-column>

              <!-- <el-table-column prop="require" label="参赛要求">
              </el-table-column> -->
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="editFunStu('edit', scope.row)"
                    type="primary"
                    plain
                    size="mini"
                    >查看</el-button
                  >
                  <!-- <el-divider direction="vertical"></el-divider>
              
                <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delFun(scope.row._id)">
                  <el-button slot="reference" type="text" size="small" 
                    >删除</el-button
                  >
                </el-popconfirm> -->
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 15, 20, 25]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              style="text-align: right; margin-top: 10px"
              v-if="total > 0"
            >
            </el-pagination>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="70%"
      :close-on-click-modal="true"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="formName"
          label-width="100px"
          class="demo-ruleForm"
          :disabled="!edit"
        >
          <el-form-item
            label="项目名称"
            prop="title"
            style="width: 50%; display: inline-block"
          >
            <el-input v-model="ruleForm.title" placeholder='请给项目起个名字吧，例如："面向操作系统课程的操作系统竞赛和实验".'/>
          </el-form-item>
          <el-form-item
            label="大赛名称"
            prop="project"
            style="width: 50%; display: inline-block"
          >
            <el-input v-model="ruleForm.project" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="info">
            <el-input v-model="ruleForm.info" type="textarea" placeholder="请尽量详细地描述该项目，例如：
各高校的计算机专业普遍都开设OS课，由于培养目标的差异，不同高校OS课程的要求是不同的；即使是同一所大学的学生，对OS课程&实验的需求也会有所不同，甚至有些学生还会通过参加操作系统...
"></el-input>
          </el-form-item>
          <!-- <el-form-item label="导师" prop="tutors">
        
            <el-button type="primary" size="mini">添加导师</el-button>
            
          </el-form-item> -->
          <el-form-item label="导师" prop="tutors" style="width: 100%;text-align: left;">
            <!-- <el-button size="small" @click="open">展开</el-button> -->
            <!-- <i class="el-icon-circle-plus-outline"></i> -->
            <el-button
              type="success"
              @click="handleAdd"
              size="mini"
              style="float: left; margin:9px 10px 5px 0"
              >添加</el-button
            >
            <span>(项目的导师联络信息，包括姓名、Github ID、email信息)</span>

            <el-table
              :data="dataSource"
              style="width: 100%"
              max-height="300"
              border
            >
              <el-table-column prop="name" label="姓名" :resizable="false">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name" placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="手机号" :resizable="false">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.phone" placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="github"
                label="Github ID"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.github"
                    placeholder=""
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="邮箱" :resizable="false">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.email" placeholder=""></el-input>
                </template>
              </el-table-column>
              <!-- <el-table-column
              prop="emial"
              label="邮箱"
    
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.email"
                  placeholder="请输入邮箱"
                ></el-input>
              </template>
            </el-table-column> -->

              <el-table-column
                label="操作"
                width="120"
                fixed="right"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <!--   <a-popconfirm
              
                  :title="`确定删除账号？`"
                  cancelText="取消"
                  okText="确定"
                  @confirm="() => onDelete(scope.$index, dataSource)"
                > -->
                  <a
                    v-if="edit"
                    href="javascript:;"
                    @click="() => onDelete(scope.$index, dataSource)"
                    >删除</a
                  >
                  <!-- </a-popconfirm> -->
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item
            label="难度"
            prop="level"
            style="width: 50%; display: inline-block"
          >
            <el-select
              v-model="ruleForm.level"
    
              style="width: 100%"
              placeholder='难度等级，请选择“低”、“中”，或者"高".'
            >
              <el-option label="低" value="低"></el-option>
              <el-option label="中" value="中"></el-option>
              <el-option label="高" value="高"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="支持单位"
            prop="company"
            style="width: 50%; display: inline-block"
          >
            <el-input v-model="ruleForm.company" placeholder="请填写您的单位，学校或企业等."></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="tags">
            <!-- <el-cascader
      
            size="mini"
            style="width:100%"
            v-model="tags"
            multiple
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChangeTag"
          ></el-cascader> -->
            <el-select
              v-model="ruleForm.tags"
              placeholder="请选择"
              multiple
              style="width: 100%"
              multiple-limit="2"
              @change="handleChangeTag"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.label
                }}</span>
                <span style="float: left">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题目要求" prop="inftopicreqo">
            <el-input v-model="ruleForm.topicreq" type="textarea" placeholder="请填写您对完成此题目的要求或需注意的事项."></el-input>
          </el-form-item>
          <el-form-item label="特征" prop="feature">
            <el-input v-model="ruleForm.feature" type="textarea" placeholder="请描述项目的特征，例如：
- 从不同的维度（使用OS、扩展OS、实现OS、分析OS、硬件特征、应用需求....）设计操作系统相关的实验内容和比赛项目指导教程
- 文档、代码、问题、答疑交互过程都开放和开源的
- 支持各种硬件
-...
"></el-input>
          </el-form-item>
          <el-form-item label="预期目标" prop="target">
            <el-input v-model="ruleForm.target" type="textarea" placeholder="请说明项目预期达到何种目标？例如：
- 设计实现操作系统课核心算法的演示实验或工具；
- 设计不同难度和不同实验环境的操作系统教学实验；
- ..."></el-input>
          </el-form-item>

          <el-form-item label="License" prop="license">
            <el-input v-model="ruleForm.license" type="textarea" placeholder="某种开源协议，推荐GPL和CC开源协议，例如
- GPL-3.0 License
- ...
"></el-input>
          </el-form-item>
          <el-form-item label="参考资料" prop="refdata">
            <el-input v-model="ruleForm.refdata" type="textarea" placeholder="列出有利于开展项目的已有参考文献、开源代码等，例如：
- [xv6](https://github.com/mit-pdos/xv6-riscv-fall19)
- [ChCore](https://gitee.com/ipads-lab/chcore-lab-v2)
- ...
"></el-input>
          </el-form-item>

          <el-form-item label="备注" prop="comment">
            <el-input v-model="ruleForm.comment" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" v-if="edit">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="okFun('formName')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看"
      :visible.sync="listvideo"
      width="70%"
      :close-on-click-modal="true"
    >
      <el-descriptions title="赛题项目" direction="vertical" :column="2" border>
        <el-descriptions-item label="项目名称">{{
          ruleForm.title
        }}</el-descriptions-item>
        <el-descriptions-item label="大赛名称">{{
          ruleForm.project
        }}</el-descriptions-item>
        <el-descriptions-item label="描述" :span="2">
          <VueMarkdown
            :source="ruleForm.info"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>
        <el-descriptions-item label="导师" :span="2">
          <el-tag v-for="(item, index) in dataSource" :key="index">
            姓名：{{ item.name }}、Github ID：{{ item.github }}、邮箱：{{
              item.email
            }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="难度">{{
          ruleForm.level
        }}</el-descriptions-item>
        <el-descriptions-item label="支持单位">{{
          ruleForm.company
        }}</el-descriptions-item>
        <el-descriptions-item label="分类" :span="2">
          <el-tag
            v-for="(item, index) in ruleForm.tags"
            :key="index"
            style="margin-right: 5px"
          >
            {{ item }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="题目要求" :span="2">
          <VueMarkdown
            :source="ruleForm.topicreq"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>
        <el-descriptions-item label="特征" :span="2">
          <VueMarkdown
            :source="ruleForm.feature"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>
        <el-descriptions-item label="预期目标" :span="2">
          <VueMarkdown
            :source="ruleForm.target"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>

        <el-descriptions-item label="License" :span="2">
          <VueMarkdown
            :source="ruleForm.license"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>

        <el-descriptions-item label="参考资料" :span="2">
          <VueMarkdown
            :source="ruleForm.refdata"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>

        <!-- <el-descriptions-item label="参赛要求" :span="2">{{
          ruleForm.require
        }}</el-descriptions-item> -->

        <el-descriptions-item label="备注" :span="2">
          <VueMarkdown
            :source="ruleForm.comment"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog
      title="选题队伍"
      :visible.sync="choosevise"
      width="70%"
      :close-on-click-modal="true"
    >
      <el-table
        :data="chooseData"
        height="450"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="title" label="队伍名称" width="180">
        </el-table-column>
        <el-table-column prop="teamid" label="队伍编号" width="180">
        </el-table-column>
        <el-table-column prop="captain" label="队长"> </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
        </el-table-column>
     

        <el-table-column prop="school" label="院校"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
import VueMarkdown from "vue-markdown";
export default {
  components: {
    heade,
    VueMarkdown,
  },
  data() {
    return {
      tags: [],
      tagsValue: "",
      tagsValueS:'',
      options: [],
      optionsS:[],
      chooseData: [],
      choosevise: false,
      listvideo: false,
      edit: false,
      op: "add",
      total: 0,
      dataSource: [],
      title: "发表赛题项目",
      dialogVisible: false,
      currentPage4: 1,
      project: "",
      limit: 10,
      page: 1,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      ruleForm: {
        title: "",
        project: this.projectName,
        info: "",
        tags: [],
        tutors: "",
        level: "低",
        require: "",
        topicreq: "",
        target: "",
        company: "",
        comment: "",
        feature: "",
        license: "",
        refdata: "",
      },
      company: "",
      level: "",
      titleS: "",

      rules: {
        title: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        project: [
          { required: true, message: "请输入大赛名称", trigger: "blur" },
        ],
        info: [{ required: true, message: "请输入描述", trigger: "blur" }],
        tutors: [{ required: true, message: "请添加导师", trigger: "blur" }],
        level: [{ required: true, message: "请选择难度", trigger: "change" }],
        tags: [{ required: true, message: "请选择分类", trigger: "change" }],
      },
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      tableData: [],
      tableDatak: [],
      id: "",
      role: "未登录",
      projectName: "",
      typeStyle:'',
    };
  },
  mounted() {
    // if (window.location.href.indexOf("?TYPE=DB") != -1) {
    //   // 说明是分享的数据库连接 那就要默认id和title
    //   this.projectName = "2023全国大学生计算机系统能力大赛数据库系统设计赛";
    //   this.curId = "63eb455d6cdc564ea96d520a";
    // } else if (window.location.href.indexOf("?TYPE=OS_N") != -1) {
    //   //说明是分享的操作系统内核 那就要默认id和title
    //   this.projectName = "2023全国大学生计算机系统能力大赛操作系统设计赛-内核实现赛";
    //   this.curId = "63eb51faa3a2834804263a15";
    // } else if (window.location.href.indexOf("?TYPE=OS_G") != -1) {
    //   //说明是分享的操作系统功能 那就要默认id和title
    //   this.projectName = "2023全国大学生计算机系统能力大赛操作系统设计赛-功能挑战赛";
    //   this.curId = "63eb51fda3a2834804263a16";
    // } else if (window.location.href.indexOf("?TYPE=CO_S") != -1) {
    //   //说明是分享的编译设计 那就要默认id和title
    //   this.projectName = "2023全国大学生计算机系统能力大赛编译系统设计赛";
    //   this.curId = "63eb51b8a3a2834804263a07";
    // } else if (window.location.href.indexOf("?TYPE=CO_T") != -1) {
    //   //说明是分享的编译挑战 那就要默认id和title
    //   this.projectName = "2023全国大学生计算机系统能力大赛编译系统挑战赛";
    //   this.curId = "640847a5ae05d248b9414cc8";
    // } else if (window.location.href.indexOf("?TYPE=CO_M") != -1) {
    //   //说明是分享的编译挑战 那就要默认id和title
    //   this.projectName = "全国大学生计算机系统能力大赛-智能系统创新大赛";
    //   this.curId = "658b8fb09b45705efe0fc7f2";
    // } else {

    //   this.projectName = window.localStorage.getItem("anjing-name");
    //   this.curId = window.localStorage.getItem("anjing-home_id");
    // }
    if (window.location.href.indexOf("?TYPE") != -1) {
      console.log(this.$route.query.TYPE);
      this.getType(this.$route.query.TYPE);
    } else {
      this.projectName = window.localStorage.getItem("anjing-name");
      // this.curId = window.localStorage.getItem("anjing-home_id");
      this.typeStyle =  window.localStorage.getItem("anjing-urltype");
      // this.init();
      if (
        window.localStorage.getItem("anjing-roleName") &&
        window.localStorage.getItem("anjing-roleName") != ""
      ) {
        this.role = window.localStorage.getItem("anjing-roleName");
        if (this.role == "学生") {
          this.allList();
        } else {
          this.init();
          this.getCompany();
        }
      } else {
        this.allList();
      }
    }

    this.labellist();
  },
  methods: {
    getType(type) {
      let data = {
        op: "getmatch",
        type: type,
        // title: title,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.projectName = response.data.data.title;
            // _this.curId = response.data.data._id;
            _this.typeStyle = response.data.data.urltype;
            console.log(response.data);
            if (
              window.localStorage.getItem("anjing-roleName") &&
              window.localStorage.getItem("anjing-roleName") != ""
            ) {
              _this.role = window.localStorage.getItem("anjing-roleName");
              if (_this.role == "学生") {
                _this.allList();
              } else {
                _this.init();
                _this.getCompany();
              }
            } else {
              _this.allList();
            }
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          _this.$message.error("接口数据异常");
          console.log(error);
        });
    },
    // 获取分类
    labellist() {
      let data = {
        op: "labellist",
        project:window.localStorage.getItem("anjing-stitleNew")
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          console.log(response.data.value);
          if (response.data.value == "ok") {
            // _this.$router.push({ path: URL });
            _this.options = response.data.data;

            // _this.total = 0
          } else {
            // _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取单位
    getCompany() {
      let data = {
        op: "getcompany",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          console.log(response.data.value);
          if (response.data.value == "ok") {
            // _this.$router.push({ path: URL });
            _this.ruleForm.company = response.data.data;
            console.log(_this.ruleForm.company);
            // _this.total = 0
          } else {
            // _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    init() {
      let data = {
        op: "mylist",
        project: this.projectName,
        // page: this.page,
        // limit: this.limit,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$router.push({ path: URL });
            _this.tableData = response.data.data;
            // _this.total = 0
          } else if (response.data.value == "sessionerror") {
            _this.$message.info(response.data.msg);
            _this.$router.push({
              path: "./login-reg-pass",
              query: { url: "./index" },
            });
          } else {
            // _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    look(url) {
      this.$router.push({
        path: url,
        query:{TYPE:this.typeStyle}
      });
    },
    confirm(e) {
      console.log(e);
      this.$message.success("Click on Yes");
      this.visible = false;
    },
    cancel(e) {
      console.log(e);
      this.$message.error("Click on No");
      this.visible = false;
    },
    handleChange(value) {
      console.log(value);

      this.tagsValue = value;
      this.labellistS(value)
      this.allList();
    },
    labellistS(label){
      let data = {
        op: "innerlabel",
        label:label
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          console.log(response.data.value);
          if (response.data.value == "ok") {
            // _this.$router.push({ path: URL });
            _this.optionsS = response.data.data;

            // _this.total = 0
          } else {
            // _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleChangeS(value) {
      console.log(value);

      this.tagsValueS = value;
      this.allList();
    },

    handleChangeTag(value) {
      console.log(value);
      if (value.length > 2) {
        this.$message.info("分类最多选择两项！");
        return;
      }
      this.ruleForm.tags = value;
    },
    editFunStu(type, obj = {}) {
      this.listvideo = true;
      this.edit = obj.edit;
      this.id = obj._id;
      this.title = "赛题项目";
      this.ruleForm.title = obj.title;

      this.ruleForm.info = obj.info;
      this.ruleForm.tutors = obj.tutors;
      this.dataSource = obj.tutors;
      // 导师的数组
      this.ruleForm.level = obj.level;
      // this.ruleForm.require = obj.require;
      this.ruleForm.topicreq = obj.topicreq;
      this.ruleForm.target = obj.target;
      this.ruleForm.project = obj.project;
      this.ruleForm.company = obj.company;
      this.ruleForm.comment = obj.comment;
      this.ruleForm.feature = obj.feature;
      this.ruleForm.license = obj.license;
      this.ruleForm.refdata = obj.refdata;
      this.ruleForm.tags = obj.tags;
      console.log(this.ruleForm.tags);
    },
    chooseFun(id) {
      this.choosevise = true;
      let data = {
        op: "chooselist",
        _id: id,
        // page: this.page,
        // limit: this.limit,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$router.push({ path: URL });
            _this.chooseData = response.data.data;
            // _this.total = 0
          } else {
            // _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editFun(type, obj = {}) {
      this.dialogVisible = true;
      this.op = type;
      this.ruleForm.project = this.projectName;
      if (type == "add") {
        this.id = "";
        this.title = "发表赛题项目";

        this.ruleForm.title = "";

        this.ruleForm.info = "";
        this.ruleForm.tutors = [];
        this.dataSource = [];
        // 导师的数组
        this.ruleForm.level = "低";
        this.ruleForm.require = "";
        this.ruleForm.topicreq = "";
        this.ruleForm.target = "";
        this.getCompany();
        // this.ruleForm.company = "";
        this.ruleForm.comment = "";
        this.ruleForm.feature = "";
        this.ruleForm.license = "";
        this.ruleForm.refdata = "";
        this.ruleForm.tags = [];
        this.edit = true;

        // 新建
      } else {
        this.edit = obj.edit;
        this.id = obj._id;
        this.title = "赛题项目";
        this.ruleForm.title = obj.title;

        this.ruleForm.info = obj.info;
        this.ruleForm.tutors = obj.tutors;
        this.dataSource = obj.tutors;
        // 导师的数组
        this.ruleForm.level = obj.level;
        // this.ruleForm.require = obj.require;
        this.ruleForm.topicreq = obj.topicreq;
        this.ruleForm.target = obj.target;

        this.ruleForm.company = obj.company;
        this.ruleForm.comment = obj.comment;
        this.ruleForm.feature = obj.feature;
        this.ruleForm.license = obj.license;
        this.ruleForm.refdata = obj.refdata;
        this.ruleForm.tags = obj.tags;

        console.log(obj);
      }
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      console.log(e);
      this.visible = false;
    },

    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.allList();
    },
    handleCurrentChange(val) {
      this.page = val;
      console.log(`当前页: ${val}`);
      this.allList();
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        name: "",
        phone: "",
        email: "",
        github: "",
        //  email: "",
      };
      this.dataSource = [...dataSource, newData];
      this.ruleForm.tutors = this.dataSource;

      this.count = count + 1;
      //  this.dis = false;
    },
    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    okFun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 导师里面的信息都为必填
          for (let i = 0; i < this.dataSource.length; i++) {
            if (
              this.dataSource[i].name == "" ||
              this.dataSource[i].phone == "" ||
              this.dataSource[i].email == "" ||
              this.dataSource[i].github == ""
            ) {
              this.$message.info(
                `第${
                  i + 1
                }行的导师信息姓名、手机号、Github ID、邮箱均为必填项！`
              );
              return;
            }
          }
          if (this.ruleForm.tags.length > 2) {
            this.$message.info("分类最多选择两项！");
            return;
          }
          // alert('submit!');
          let data = {
            op: this.op,
            project: this.projectName,
            title: this.ruleForm.title,
            info: this.ruleForm.info,
            tutors: this.dataSource.length != 0 ? this.dataSource : "",
            level: this.ruleForm.level,
            // require: this.ruleForm.require,
            topicreq: this.ruleForm.topicreq,
            target: this.ruleForm.target,
            company: this.ruleForm.company,
            comment: this.ruleForm.comment,
            _id: this.id,
            feature: this.ruleForm.feature,
            license: this.ruleForm.license,
            refdata: this.ruleForm.refdata,
            tags: this.ruleForm.tags,
          };
          let _this = this;

          this.$ajax
            .post(this.url + "/topics.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.dialogVisible = false;
                _this.init();
                _this.$messages.success(response.data.msg);
                // _this.$router.push({ path: URL });
                // _this.tableData = response.data.data;
                // _this.total = 0
              } else {
                _this.$message.info(response.data.msg);
                if (response.data.msg.indexOf("题目名称不能重复") != -1) {
                  _this.dialogVisible = true;
                } else {
                  _this.dialogVisible = false;
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      console.log(row);
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    handleClick(tab) {
      console.log(tab === 2);
      if (tab == 2) {
        this.allList();
      }
    },
    allList(type='ok') {
      if(type=='zhi'){
        this.page = 1
      }
      let data = {
        op: "all",
        project: this.projectName,
        page: this.page,
        limit: this.limit,
        company: this.company,
        level: this.level,
        title: this.titleS,
        tags: this.tagsValue,
        tags_s:this.tagsValueS,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$router.push({ path: URL });
            _this.tableDatak = response.data.data;

            _this.total = response.data.recordcount; //
          } else if (response.data.value == "sessionerror") {
            _this.$message.info(response.data.msg);
            // _this.$router.push({
            //   path: "./login-reg-pass",
            //   query: { url: "./index" },
            // });
          } else {
            _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    delFun(id) {
      console.log(id);
      let data = {
        op: "del",
        _id: id,
      };
      let _this = this;
      this.dialogVisible = false;
      this.$ajax
        .post(this.url + "/topics.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.init();
            _this.$message.success(response.data.msg);
            // _this.$router.push({ path: URL });
            // _this.tableData = response.data.data;
            // _this.total = 0
          }
          // else if (response.data.value == "sessionerror") {
          //   _this.$message.info(response.data.msg);
          //   _this.$router.push({
          //     path: "./login-reg-pass",
          //     query: { url: "./index" },
          //   });
          // }
          else {
            _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.list {
}
.list-box {
  padding: 20px 2%;
}
.mbx {
  text-align: left;
  /* margin-bottom:20px; */
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
/* .markdown-body pre code{
  white-space: pre-wrap !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
} */
</style>
