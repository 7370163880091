var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade',{attrs:{"currentrStr":""}}),_c('div',{staticClass:"sai common-width"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.to('./index')}}},[_vm._v("上一页")])]),_c('a-breadcrumb-item',[_vm._v("技术报告")])],1),_vm._l((_vm.allAry),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"look-del"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"pad-left"},[_vm._v("1. 直播时间："+_vm._s(item.time))]),_c('p',{staticClass:"pad-left"},[_vm._v(" 2. 培训日程与直播地址： "),_c('a',{attrs:{"target":"_blank","href":item.href1}},[_vm._v(" 点击查看")])]),_c('p',{staticClass:"pad-left"},[_vm._v(" 3. 直播回放： "),_c('a',{attrs:{"target":"_blank","href":item.href2}},[_vm._v("点击查看回放")])]),_c('a-table',{staticStyle:{"margin-top":"10px"},attrs:{"bordered":"","columns":_vm.columns,"data-source":item.dataColumns,"pagination":false},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return (record.href3 != '')?_c('a',{attrs:{"target":"_blank","href":record.href3}},[_vm._v(_vm._s(record.hrefName))]):_vm._e()}}],null,true)})],1)}),_c('p',{staticClass:"look-del"},[_vm._v("rCore 学习小组分享会")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('h4',[_vm._v("直播回放：")]),_vm._m(7),_c('p',{staticClass:"pad-left"},[_vm._v(" 温馨提示：输入手机号登录，无需下载安装app，报名后直接在电脑上用 Chrome 浏览器进入教室听课。 ")])],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticStyle:{"display":"inline-block","margin-top":"10px"}},[_vm._v("直播时间：")]),_vm._v(" 2022年4月15日20：00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticStyle:{"display":"inline-block"}},[_vm._v("主题：")]),_vm._v(" 非对称多核平台的RustSBI及其功能实现 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticStyle:{"display":"inline-block"}},[_vm._v("分享人：")]),_vm._v(" 洛佳——华中科技大学大学信息安全专业本科大四 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("分享内容：")]),_c('p',{staticClass:"pad-left"},[_vm._v(" （1）简介RISC-V、SBI接口与RustSBI软件,非对称架构下处理器固件与内核开发的技术难点 ")]),_c('p',{staticClass:"pad-left"},[_vm._v(" （2）实现与使用RustSBI在HiFive Unmatched上的SBI标准环境 ")]),_c('p',{staticClass:"pad-left"},[_vm._v("（3）在您的操作系统竞赛参赛作品中使用RustSBI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticStyle:{"display":"inline-block","margin-top":"20px"}},[_vm._v("主题：")]),_vm._v(" zCore 近期开发进度 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticStyle:{"display":"inline-block"}},[_vm._v("分享人：")]),_vm._v(" 杨德睿——启元实验室工程师 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("分享内容：")]),_c('p',{staticClass:"pad-left"},[_vm._v("（1）在 D1 开发板上开启 UART5（美丽的硬编码）")]),_c('p',{staticClass:"pad-left"},[_vm._v(" （2）减少系统调用陷入内核后使用用户指针的拷贝开销 ")]),_c('p',{staticClass:"pad-left"},[_vm._v("（3）实现 Todo/修 Bug")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pad-left"},[_vm._v(" 前往观看： "),_c('a',{attrs:{"href":"https://rust.os2edu.cn/pages/course/103","target":"_blank"}},[_vm._v(" https://rust.os2edu.cn/pages/course/103")])])
}]

export { render, staticRenderFns }