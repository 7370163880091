<template>
    <div class="about">
        <heade currentrStr="info"></heade>
        <div class="common-width content">
          <el-breadcrumb separator="/" >
          <el-breadcrumb-item :to="{ path: 'info' }">通知、新闻</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/">内容详情</a></el-breadcrumb-item>
          </el-breadcrumb>
        <!-- <div class="articalContent markdown-body" style="margin-top:20px;">{{content}}</div> -->
        <VueMarkdown :source="content"  class="articalContent markdown-body" style="margin-top:20px;"></VueMarkdown>
        </div>
        
  
       
    </div>
  </template>
  <script>
  import VueMarkdown from 'vue-markdown'
import heade from "@/components/header.vue";
  export default {
    data(){
      return {
       content: window.localStorage.getItem('anjing_ds_content')

      
      }
    },
    components: {
        heade,
        VueMarkdown
    },
    mounted(){
      console.log(this.content)
   

  
    },
    methods:{
    }
  };
  </script>
  <style scoped>
  @import '../assets/markdown.css';
  .content{
    margin-top:24px !important;;
  }
  </style>

  