<template>
    <div>
        <heade currentrStr="ererre" ></heade>

    <div  class='common-width' style="padding-top:80px;">

        <ul style=" padding-bottom: 10px" class="footer-all">
          <li>
            <span style="display:block;font-size:20px;font-weight:bold">钻石赞助</span>
            <span style="width:70%"><a href="https://www.huawei.com/" target="_blank"
                >
                <img src="../images/huawei.png" width="50px;" />
                华为技术有限公司
              </a>
              <span style="margin: 0 10px">|</span>
              <a href="http://www.kylinos.cn/" target="_blank"
                >
                <img src="../images/qilin.png" width="50px;" /> 
                麒麟软件
                </a
              >
              <span style="margin: 0 10px">|</span>
              <a href="https://www.uniontech.com/" target="_blank"
                >
                <img src="../images/tongxin.png" width="70px;" /> 
                统信软件
                </a
              >
      
            </span>
          </li>
          <li>
            <span style="display:block;font-size:20px;font-weight:bold">金牌赞助</span>
            <span style="width:70%">
              <a href="https://openanolis.cn/" target="_blank"
                >
                <img src="../images/longxi.png" width="100px;" />
                龙蜥社区
              </a>
              <span style="margin: 0 10px">|</span>
              <a href="http://www.ucas.com.cn/html/about/intro/" target="_blank"
                >
                <img src="../images/guoke.png" width="20px;" /> 
                国科环宇
                </a
              >
              <span style="margin: 0 10px">|</span>
              <a href="http://www.loongson.cn/" target="_blank" style="  display:inline-block;margin:8px 0;">
                <img src="../images/longxin.jpg" width="50px;" /> 
                龙芯中科
                </a
              >
              <span style="margin: 0 10px">|</span>
              <a href="https://www.antgroup.com/" target="_blank" style="  display:inline-block;margin:8px 0;">
                <img src="../images/mayi.png" width="80px;" /> 
                蚂蚁集团
                </a
              >
              <span style="margin: 0 10px">|</span>
              <a href="https://www.mi.com/" target="_blank"
                >
                <img src="../images/xiaomi.png" width="30px;" />
                小米科技有限责任公司
                </a
              >
              <span style="margin: 0 10px">|</span>
              <a href="https://www.bytedance.com/zh" target="_blank">
                <img src="../images/zijie.png" width="80px;" />
                字节跳动
              </a>
              
              </span
            >
          </li>
<!-- 
          <li>
            <span style="display: inline-block; width: 100%">
              <a href="http://www.ucas.com.cn/html/about/intro/" target="_blank"
                ><img src="../images/guoke.png" width="20px;" /> 国科环宇</a
              >
              <span style="margin: 0 10px">|</span>
              <a href="http://www.loongson.cn/" target="_blank">
                <img src="../images/longxin.jpg" width="30px;" /> 龙芯中科</a
              >
            </span>
          </li> -->
          <li>
            <span style="display:block;font-size:20px;font-weight:bold">铜牌赞助</span>
            <span style="width:70%">
              <a href="https://xcalibyte.com.cn/" target="_blank" style="  display:inline-block;margin:8px 0;"
                >
                <img src="../images/jianshi.svg" width="40px;" />
                鉴释科技
              </a>
              <span style="margin: 0 10px">|</span>
              <a href="https://www.acoinfo.com/" target="_blank">
                <img src="../images/yihui.svg" width="80px;" /> 
                翼辉信息
                </a
              >
              <span style="margin: 0 10px">|</span>
              <a href="https://www.isyscore.com/" target="_blank">
                <img src="../images/zhiling.png" width="35px;" /> 
                指令集
                </a
              >
            </span>
          </li>
          <!-- <li>
            <span>
              <a href="https://www.bytedance.com/zh" target="_blank">
                <img src="../images/zijie.png" width="60px;" />字节跳动
              </a>
              <span style="margin: 0 10px">|</span
              ><a href="https://xcalibyte.com.cn/" target="_blank"
                ><img src="../images/jianshi.svg" width="25px;" />
                鉴释科技
              </a>

            </span>
          </li>
          <li>
            <span style="display: inline-block; width: 100%">
              <a href="https://www.acoinfo.com/" target="_blank">
                <img src="../images/yihui.svg" width="50px;" /> 翼辉信息</a
              >
              <span style="margin: 0 10px">|</span>
              <a href="https://www.isyscore.com/" target="_blank">
                <img src="../images/zhiling.png" width="20px;" /> 指令集</a
              ></span
            >
          </li> -->
        </ul>
    </div>
</div>
</template>
<script>
import heade from "@/components/header.vue";
export default {
    components: {
    heade
    }
}
</script>
<style scoped>
div,p,span,ul,li{
    padding:0;margin:0;
    list-style: none;
}
li{
    margin-bottom:40px;
}
</style>