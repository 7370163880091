<template>
    <div>
        <heade currentrStr="ererre" ></heade>

    <div  class='common-width' style="padding-top:30px;">
      <img src="../images/zphb001.png" style="width:80%"/>
  
      <p style="margin:30px 0;font-size:20px;font-weight:bold">    <el-divider>各企业如需发布招聘信息，请联系<i class="el-icon-bottom"></i></el-divider></p>
      <img src="../assets/WechatIMG29605.png" width="100px"  style="margin-bottom:20px"/>
    </div>
</div>
</template>
<script>
import heade from "@/components/header.vue";
export default {
    components: {
    heade
    }
}
</script>
<style scoped>
div,p,span,ul,li{
    padding:0;margin:0;
    list-style: none;
}
li{
    margin-bottom:40px;
}
</style>