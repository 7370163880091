<template>
  <div>
    <heade currentrStr=""></heade>
    <div class="sai common-width">
      <a-breadcrumb>
        <a-breadcrumb-item
          ><a href="javascript:;" @click="to('./index')"
            >上一页</a
          ></a-breadcrumb-item
        >
        <a-breadcrumb-item>技术报告</a-breadcrumb-item>
      </a-breadcrumb>
      <div v-for="(item, index) in allAry" :key="index">
        <p class="look-del">
          {{ item.title }}
        </p>
        <p class="pad-left">1. 直播时间：{{ item.time }}</p>
        <p class="pad-left">
          2. 培训日程与直播地址：
          <a target="_blank" :href="item.href1"> 点击查看</a>
        </p>
        <p class="pad-left">
          3. 直播回放：
          <a target="_blank" :href="item.href2">点击查看回放</a>
        </p>
        <a-table
          bordered
          :columns="columns"
          :data-source="item.dataColumns"
          style="margin-top: 10px"
          :pagination="false"
        >
          <a
            slot="action"
            slot-scope="text, record"
            target="_blank"
            :href="record.href3"
            v-if="record.href3 != ''"
            >{{ record.hrefName }}</a
          >
        </a-table>
      </div>
      <p class="look-del">rCore 学习小组分享会</p>
      <div>
        <h4 style="display: inline-block; margin-top: 10px">直播时间：</h4>
        2022年4月15日20：00
      </div>
      <div>
        <h4 style="display: inline-block">主题：</h4>
        非对称多核平台的RustSBI及其功能实现
      </div>
      <div>
        <h4 style="display: inline-block">分享人：</h4>
        洛佳——华中科技大学大学信息安全专业本科大四
      </div>
      <div>
        <h4>分享内容：</h4>
        <p class="pad-left">
          （1）简介RISC-V、SBI接口与RustSBI软件,非对称架构下处理器固件与内核开发的技术难点
        </p>
        <p class="pad-left">
          （2）实现与使用RustSBI在HiFive Unmatched上的SBI标准环境
        </p>
        <p class="pad-left">（3）在您的操作系统竞赛参赛作品中使用RustSBI</p>
      </div>

      <div>
        <h4 style="display: inline-block; margin-top: 20px">主题：</h4>
        zCore 近期开发进度
      </div>
      <div>
        <h4 style="display: inline-block">分享人：</h4>
        杨德睿——启元实验室工程师
      </div>
      <div>
        <h4>分享内容：</h4>
        <p class="pad-left">（1）在 D1 开发板上开启 UART5（美丽的硬编码）</p>
        <p class="pad-left">
          （2）减少系统调用陷入内核后使用用户指针的拷贝开销
        </p>
        <p class="pad-left">（3）实现 Todo/修 Bug</p>
      </div>
      <h4>直播回放：</h4>
      <p class="pad-left">
        前往观看：
        <a href="https://rust.os2edu.cn/pages/course/103" target="_blank">
          https://rust.os2edu.cn/pages/course/103</a
        >
      </p>
      <p class="pad-left">
        温馨提示：输入手机号登录，无需下载安装app，报名后直接在电脑上用 Chrome
        浏览器进入教室听课。
      </p>
    </div>
  </div>
</template>
<script>
const columns = [
  { title: "题目", dataIndex: "name", key: "name" },
  { title: "讲者", dataIndex: "age", key: "age" },
  {
    title: "操作",
    dataIndex: "address",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
];

import heade from "@/components/header.vue";
export default {
  components: {
    heade,
  },
  data() {
    return {
      columns,
      allAry: [],
    };
  },
  mounted() {
    this.allAry = [
      {
        title: " 2022全国大学生系统能力大赛操作系统设计赛决赛第五场技术培训会",
        time: "2022年8月14日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/3c5MtWHQaber3bnpj-clCg",
        href2:
          "https://pan.educg.net/api/v3/file/get/13804/2022%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%A4%A7%E8%B5%9B%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E6%8A%80%E6%9C%AF%E4%BA%A4%E6%B5%81%E4%BC%9A%E7%AC%AC3%E5%9C%BA.mp4?sign=I_7spSMuGkRFvdMLcZbFx0_XLCaHBcDuynSEgw-q7LA%3D%3A0",
        dataColumns: [
          {
            name: `主持人`,
            age: "孙卫真",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `AI 平台与操作系统`,
            age: "刘喆",
            href3: "https://pan.educg.net/#/s/KeMTa",
            hrefName: "下载",
          },
          {
            name: `基于用户态中断的进程间通信以及异步系统调用`,
            age: "项晨东 王之栋 孙迅",
            href3: "https://pan.educg.net/#/s/VeRHq",
            hrefName: "下载",
          },
          {
            name: `基于Rust重构安全TeeOS`,
            age: "孙宇涛 安一帆 赖瀚宇",
            href3: "https://pan.educg.net/#/s/xYJtk",
            hrefName: "下载",
          },
          {
            name: `基于Rust的Linux内核虚拟机 -- R-KVM`,
            age: "安之达",
            href3: "https://pan.educg.net/#/s/J5ZHX",
            hrefName: "下载",
          },
          {
            name: `YOUBIFS（实现一个更智能的Flash文件系统）`,
            age: "黄浩 庞力源 董言昕",
            href3: "https://pan.educg.net/#/s/zR8IO",
            hrefName: "下载",
          },
          {
            name: `OSdetector（proj-120实现智能化的操作系统异常检测）`,
            age: "于伯淳 满洋 李怡凯",
            href3: "https://pan.educg.net/#/s/Ze5sR",
            hrefName: "下载",
          },
          {
            name: `Eunomia：一个基于eBPF技术监控容器行为的工具`,
            age: "郑昱笙 张典典 濮雯旭",
            href3: "https://pan.educg.net/#/s/We4i5",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛决赛第四场技术培训会",
        time: "2022年8月7日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/3c5MtWHQaber3bnpj-clCg",
        href2:
          "https://pan.educg.net/api/v3/file/get/13804/2022%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%A4%A7%E8%B5%9B%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E6%8A%80%E6%9C%AF%E4%BA%A4%E6%B5%81%E4%BC%9A%E7%AC%AC3%E5%9C%BA.mp4?sign=I_7spSMuGkRFvdMLcZbFx0_XLCaHBcDuynSEgw-q7LA%3D%3A0",
        dataColumns: [
          {
            name: `主持人`,
            age: "翟一鸣",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `F-Tutorials（基于FUSE的文件系统实验指导书）`,
            age: "赵鹏宇 潘延麒 陈一邹",
            href3: "https://pan.educg.net/#/s/q2lh2",
            hrefName: "下载",
          },
          {
            name: `proj158-支持Rust语言的源代码级内核调试工具`,
            age: "陈志扬 余叶 黄宗益",
            href3: "https://pan.educg.net/#/s/dXNHv",
            hrefName: "下载",
          },
          {
            name: `proj97-la-seL4：开源操作系统的LoongArch移植-seL4微内核`,
            age: "刘庆涛 雷洋 陈洋",
            href3: "https://pan.educg.net/#/s/yLgsO",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛决赛第三场技术培训会",
        time: "2022年7月31日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/qn-pb1BXg-4WULBA7uhE6Q",
        href2:
          "https://www.bilibili.com/video/BV1hd4y1U7TH?spm_id_from=333.337.search-card.all.click",
        dataColumns: [
          {
            name: `主持人`,
            age: "孟宁",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `基于Phytium CortexA芯片的FreeRTOS Coredump机制的实现`,
            age: "曹颂",
            href3: "https://pan.educg.net/#/s/vj8h0",
            hrefName: "下载",
          },
          {
            name: `执行控制和标识程序`,
            age: "申宇翔",
            href3: "https://pan.educg.net/#/s/Mg9HB",
            hrefName: "下载",
          },
          {
            name: `linux-realtime-probe-tool（Linux 内核实时性瓶颈分析工具）`,
            age: "高世伟",
            href3: "https://pan.educg.net/#/s/5bbfe",
            hrefName: "下载",
          },
          {
            name: `使用哈希页表实现虚拟机的stage-2页表`,
            age: "周宇鑫",
            href3: "https://pan.educg.net/#/s/geAiL",
            hrefName: "下载",
          },
          {
            name: `NewF2FS`,
            age: "张祎",
            href3: "https://pan.educg.net/#/s/jjxtP",
            hrefName: "下载",
          },
          {
            name: `e$NFS`,
            age: "钟源",
            href3: "https://pan.educg.net/#/s/GROfZ",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛决赛第二场技术培训会",
        time: "2022年7月10日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/_zhjKEtnwUy2TB7S0G1QgA",
        href2:
          "https://www.bilibili.com/video/BV1pY4y1J7xg?spm_id_from=333.999.0.0&vd_source=57ee8b89964a4303d3e3fa4492aa397f",
        dataColumns: [
          {
            name: "主持人",
            age: "杨兴强",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "动态链接/加载的前沿技术",
            age: "任玉鑫",
            href3: "https://pan.educg.net/#/s/jwYiP",
            hrefName: "下载",
          },
          {
            name: "应用内核技术在蚂蚁集团实践",
            age: "贺勇",
            href3: "https://pan.educg.net/#/s/PBKIq",
            hrefName: "下载",
          },
          {
            name: "云计算环境中split locks对性能的影响",
            age: "王楠",
            href3: "https://pan.educg.net/#/s/NozIo",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛决赛第一场技术培训会",
        time: "2022年6月12日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/Z7iG4ZZushqNuAfRJPgrGQ",
        href2:
          "https://www.bilibili.com/video/BV1sT41157HN?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "主持人",
            age: "张亮",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "二进制翻译技术简介",
            age: "张福新",
            href3: "https://pan.educg.net/#/s/52Afe",
            hrefName: "下载",
          },
          {
            name: "毕昇编译器介绍及优化技术实践",
            age: "赵川峰",
            href3: "https://pan.educg.net/#/s/727hd",
            hrefName: "下载",
          },
          {
            name: "Linux内核LSM安全框架",
            age: "武耀威",
            href3: "https://pan.educg.net/#/s/vYRh0",
            hrefName: "下载",
          },
          {
            name: "操作系统应用分发技术实践",
            age: "陆达君",
            href3: "https://pan.educg.net/#/s/e37Ua",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛初赛第六场技术培训会",
        time: "2022年6月05日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/y_4lEGYiAAUxhKDT2dphXA",
        href2:
          "https://www.bilibili.com/video/BV1GZ4y1t7Qu?spm_id_from=333.999.0.0&vd_source=57ee8b89964a4303d3e3fa4492aa397f",
        dataColumns: [
          {
            name: "操作系统内核攻防的演化和展望",
            age: "申文博",
            href3: "https://pan.educg.net/#/s/A22tr",
            hrefName: "下载",
          },
          {
            name: "大规模操作系统治愈的挑战与机遇",
            age: "栾建海",
            href3: "https://pan.educg.net/#/s/82yHr",
            hrefName: "下载",
          },
          {
            name: "嵌入式Linux与实时性研究",
            age: "郭皓",
            href3: "https://pan.educg.net/#/s/pp4Ty",
            hrefName: "下载",
          },
          {
            name: "亲历OS发展三十年",
            age: "秦钢",
            href3: "https://pan.educg.net/#/s/XRVUm",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛初赛第五场技术培训会",
        time: "2022年5月29日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/W9rV3NRYgXzLaQLAcI_ZSg",
        href2:
          "https://www.bilibili.com/video/BV1UA4y1Z71v?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "操作系统的本质和技术生态",
            age: "魏永明",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "操作系统实验教学和计算机系统课程",
            age: "罗秋明",
            href3: "https://pan.educg.net/#/s/15zTl",
            hrefName: "下载",
          },
          {
            name: "eBPF概念及在可观测性、网络、安全以及性能优化方面的应用",
            age: "陈鹏飞",
            href3: "https://pan.educg.net/#/s/Kz0Fa",
            hrefName: "下载",
          },
          {
            name: "AIoT芯片及操作系统的功能演进",
            age: "黄齐",
            href3: "https://pan.educg.net/#/s/QbwiX",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛初赛第四场技术培训会",
        time: "2022年5月22日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/zTS0UeMNT7FMuI7qTv07aA",
        href2:
          "https://www.bilibili.com/video/BV1Za41177L2?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "构建高效的超大规模向量近似最近邻搜索系统",
            age: "陈琪",
            href3: "https://pan.educg.net/#/s/7P7fd",
            hrefName: "下载",
          },
          {
            name: "双系统中共享内存设计以及RPC流程介绍",
            age: "聂伟",
            href3: "https://pan.educg.net/#/s/2M3F1",
            hrefName: "下载",
          },
          {
            name: "哈工大操作系统课程建设",
            age: "李治军",
            href3: "https://pan.educg.net/#/s/XWVUm",
            hrefName: "下载",
          },
          {
            name: "操作系统教学实践",
            age: "夏虞斌",
            href3: "https://pan.educg.net/#/s/eA7Ia",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛初赛第三场技术培训会",
        time: "2022年5月15日14:00-18：00",
        href1: "https://mp.weixin.qq.com/s/O0yIXseHISrEkGGBAsyY-w",
        href2:
          "https://www.bilibili.com/video/BV12Y4y1471g?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "龙蜥社区与龙蜥操作系统介绍",
            age: "周鹏",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "龙蜥社区赛题解析",
            age: "吴一昊",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "嵌入式Linux软硬件系统开发调试",
            age: "王洪辉",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "基于QEMU模拟器的OS内核研发",
            age: "张福新",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "HyperEnclave 基于虚拟化技术构建可信执行环境",
            age: "刘双",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "资源受限下OTA静默升级系统的设计与实现",
            age: "杨冬东",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "大规模数据中心内核运维",
            age: "黄杰、尹欣",
            href3: "",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛在线技术交流会",
        time: "2022年5月14日14:00-15：30",
        href1: "https://mp.weixin.qq.com/s/7yaCOdLR3_vNFORcsQe_4A",
        href2:
          "https://www.bilibili.com/video/BV1EB4y197QU?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "2022年操作系统大赛提交与评测流程简介",
            age: "王明键",
            href3: "https://pan.educg.net/#/s/8Mycr",
            hrefName: "下载",
          },
          {
            name: "U740的多种启动方式与bootloader和系统的构建",
            age: "曹隽诚",
            href3: "https://pan.educg.net/#/s/Az2Sr",
            hrefName: "下载",
          },
          {
            name: "U740线上调试环境搭建",
            age: "唐皓泓",
            href3: "https://pan.educg.net/#/s/p44Ty",
            hrefName: "下载",
          },
          {
            name: "HifiveUnmached远程开发和NXOS移植",
            age: "胡自成",
            href3: "https://pan.educg.net/#/s/bByT3",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛初赛第二场技术培训会",
        time: "2022年4月24日14:00-18:00",
        href1: "https://mp.weixin.qq.com/s/-oU7CMKh3dnkoIPwVavoqw",
        href2:
          "https://www.bilibili.com/video/BV1K44y137zL?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "参与开源根社区，贡献软件根技术",
            age: "武延军",
            href3: "https://pan.educg.net/#/s/xZYIk",
            hrefName: "下载",
          },
          {
            name: "体系架构变革的新时代",
            age: "熊伟",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "双体系可信操作系统",
            age: "王震",
            href3: "https://pan.educg.net/#/s/JJOfX",
            hrefName: "下载",
          },
          {
            name: "国产操作系统现状与发展趋势",
            age: "李鹤",
            href3: "https://pan.educg.net/#/s/Ko0Ia",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022全国大学生系统能力大赛操作系统设计赛初赛第一场技术培训会",
        time: "2022年4月17日14:00-18:00",
        href1: "https://mp.weixin.qq.com/s/QWVdfJXb5A8ZdqZPgD-WDQ",
        href2:
          "https://www.bilibili.com/video/BV1Q34y1v7eM?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: "2022操作系统大赛情况介绍",
            age: "陈向群",
            href3: "https://pan.educg.net/#/s/Vzguq",
            hrefName: "下载",
          },
          {
            name: "华为赛题简析",
            age: "伍伯东",
            href3: "https://pan.educg.net/#/s/x8Ytk",
            hrefName: "下载",
          },
          {
            name: "麒麟赛题简析",
            age: "吴春光",
            href3: "https://pan.educg.net/#/s/J9OuX",
            hrefName: "下载",
          },
          {
            name: "统信赛题简析",
            age: "王耀华",
            href3: "https://pan.educg.net/#/s/zAwsO",
            hrefName: "下载",
          },
          {
            name: "龙蜥社区赛题简析",
            age: "梁超众",
            href3: "",
            hrefName: "下载",
          },
          {
            name: "国科环宇赛题简析",
            age: "王洪辉",
            href3: "https://pan.educg.net/#/s/Z3whR",
            hrefName: "下载",
          },
          {
            name: "龙芯中科赛题简析",
            age: "张福新",
            href3: "https://pan.educg.net/#/s/WZ1t5",
            hrefName: "下载",
          },
          {
            name: "小米-Vela团队赛题简析",
            age: "秦蔚",
            href3: "https://pan.educg.net/#/s/aYBT3",
            hrefName: "下载",
          },
          {
            name: "小米-基于zcore的Teeos",
            age: "聂伟",
            href3: "https://pan.educg.net/#/s/31muX",
            hrefName: "下载",
          },
          {
            name: "小米赛题简析",
            age: "杨冬东",
            href3: "https://pan.educg.net/#/s/zawtO",
            hrefName: "下载",
          },
          {
            name: "字节跳动赛题简析",
            age: "李泽帆",
            href3: "https://pan.educg.net/#/s/4Mwfr",
            hrefName: "下载",
          },
          {
            name: "翼辉信息赛题简析",
            age: "陈洪邦",
            href3: "https://pan.educg.net/#/s/ZpwUR",
            hrefName: "下载",
          },
          {
            name: "指令集-物联网操作系统的应用开发介绍",
            age: "徐国强",
            href3: "https://pan.educg.net/#/s/kz3c5",
            hrefName: "下载",
          },
        ],
      },
    ];
  },
  methods: {
    to(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
div,
p {
  padding: 0;
  margin: 0;
}
.look-del {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  padding-top: 20px;
}
.pad-left {
  padding: 5px 0;
  padding-left: 13px;
  text-indent: -13px;
}
.sai {
  margin-top: 20px !important;
  text-align: left;
  padding-bottom: 20px;
}
/deep/ .ant-breadcrumb {
  text-align: left;
}
</style>